

















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TheServeChangeStep',
  components: {
    BaseInput: () =>
      import(
        '@/components/Modals/Default/TwoFactorAuth/components/BaseInput.vue'
      ),
  },
})
export default class TheServeChangeStep extends Vue {
  @Prop({ type: String, required: true }) readonly value!: string
  @Prop({ type: String, required: true }) readonly error!: string

  onInput (event: InputEvent) {
    this.$emit('input', event)
    this.$emit('update:error', '')
  }
}
